<template>
<v-container fluid class="px-0">
    <v-row align="center" justify="center">
        <v-col cols="12" sm="9">
            <v-card flat class="error-card">
                <v-card-title class="justify-center pt-10">
                    <v-icon class="error-icon">{{ errorIcon }}</v-icon>
                </v-card-title>
                <v-card-text class="text-center display-1 pb-10">{{ errorText }}</v-card-text>
                <v-spacer />
                <v-card-actions class="justify-center pb-10">
                    <slot name="actions">
                        <v-btn outlined exact x-large :to="{ name: 'home' }" aria-label="Link to Home page">Go to Home</v-btn>
                    </slot>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
export default {
    name: "error",

    props: ["errorIcon", "errorText"],

    data() {
        return {};
    },
};
</script>

<style lang="scss">
.error-card {
    opacity: 0.74;
    background-size: cover;
    background-image: linear-gradient(rgba($secondary_color, 0.74), rgba($secondary_color, 0.74)), url("/static/img/main.jpg");
    .error-icon {
        width: 100%;
        height: 200px;
        .v-icon__component {
            width: 100%;
            height: 100%;
        }
    }
}
</style>